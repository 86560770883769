import axios from 'util/Api';

export const GET_COLLABORATORS = 'Collaborators/GET_COLLABORATORS';
export const GET_COLLABORATORS_SUCCESS =
  'Collaborators/GET_COLLABORATORS_SUCCESS';
export const GET_COLLABORATORS_ERROR = 'Collaborators/GET_COLLABORATORS_ERROR';

export const getCollaboratorsSuccess = collaborators => ({
  type: GET_COLLABORATORS_SUCCESS,
  payload: {
    collaborators
  }
});

export const getCollaboratorsError = error => ({
  type: GET_COLLABORATORS_SUCCESS,
  payload: {
    error
  }
});

export const getCollaborators = params => async dispatch => {
  dispatch({
    type: GET_COLLABORATORS,
    payload: {
      params
    }
  });

  try {
    const { data } = await axios.get('/collaborators', { params });
    dispatch(getCollaboratorsSuccess(data.data));
  } catch (e) {
    dispatch(getCollaboratorsError(e));
  }
};
