import { ADD_PATIENT, GET_PATIENTS, SELECTED_PATIENT, UPDATE_PATIENT } from "../../constants/patients";
const INIT_STATE = {
  patients: [],
  count: 0,
  selectedPatient: {}
}

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    case GET_PATIENTS: {
      return { ...state, patients: action.payload, count: action.count }
    }
    case ADD_PATIENT: {
      return { ...state, patients: [...state.patients, action.payload], count: state.count + 1 }
    }
    case SELECTED_PATIENT: {
      return { ...state, selectedPatient: action.payload }
    }
    case UPDATE_PATIENT: {
      const patients = state.patients.filter(patient => patient._id !== action.payload._id);
      return { ...state, patients: [...patients, action.payload] }
    }
    default:
      return state;
  }
}
