export const ADD_APPOINTMENT = "[APPOINTMENT] ADDING APPOINTMENT";
export const GET_APPOINTMENT = "[APPOINTMENT] GET APPOINTMENT";
export const SELECT_APPOINTMENT = "[APPOINTMENT] SELECT APPOINTMENT";
export const DELETE_APPOINTMENT = "[APPOINTMENT] DELETE APPOINTMENT";
export const UPDATE_APPOINTMENT = "[APPOINTMENT] UPDATE APPOINTMENT";
export const GET_REPORT_APPOINTMENTS_GROUP_STATUS = "[APPOINTMENT] GET REPORT APPOINTMENTS GROUP BY STATUS";
export const CALC_DATA_APPOINTMENTS_GROUP = "[APPOINTMENT] GET CALC APPOINTMENTS GROUP BY STATUS";
export const ADD_REPORT_APPOINTMENTS_GROUP_STATUS = "[APPOINTMENT] GET ADD APPOINTMENTS GROUP BY STATUS";
export const DEBTS_FUNDS_APPOINTMENTS = "[APPOINTMENT] GET DEBT APPOINTMENTS";
export const SET_QUERY = "[APPOINTMENT] GET SET_QUERY";
export const DELETE_APPOINTMENTS = "[APPOINTMENT] DELETE APPOINTMENTS";
export const ADD_OR_UPDATE_APPOINTMENTS = "[APPOINTMENT] ADD OR UPDATE APPOINTMENTS";
