import * as actions from '../actions/InviteCollab';

const initialState = {
  token: null,
  inviteLink: null,
  addingCollab: false,
  fetchingInviteLink: false
};

function inviteCollabReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REMOVE_INVITE_TOKEN:
      return {
        ...state,
        token: null
      };
    case actions.SET_INVITE_TOKEN:
      return {
        ...state,
        token: action.payload.token
      };
    case actions.GET_INVITE_LINK:
      return {
        ...state,
        inviteLink: null,
        fetchingInviteLink: true
      };
    case actions.GET_INVITE_LINK_SUCCESS:
      return {
        ...state,
        inviteLink: action.payload.link,
        fetchingInviteLink: false
      };
    case actions.GET_INVITE_LINK_ERROR:
      return {
        ...state,
        fetchingInviteLink: false
      };
    case actions.ADD_COLLABORATOR:
      return {
        ...state,
        addingCollab: true
      };
    case actions.ADD_COLLABORATOR_SUCCESS:
      return {
        ...state,
        token: null,
        addingCollab: false
      };
    case actions.ADD_COLLABORATOR_ERROR:
      return {
        ...state,
        token: null,
        addingCollab: false
      };
    default:
      return state;
  }
}

export default inviteCollabReducer;
