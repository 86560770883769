import {
  SET_VIDEO_CALL,
  REMOVE_VIDEO_CALL,
  ADD_VIDEO_CALL,
  CURRENT_VIDEO_CALL,
  FLAG_VIDEO_CALL, COUNT_TIMER_CALL, REFRESH_TOKEN_CALL
} from "../../constants/VideoCall";
import { get } from "lodash";
import { split } from "lodash";
import { last } from "lodash";
// const APP_ID = process.env[`REACT_APP_A_APP_ID_${process.env.REACT_APP_ENV}`];
// {
//   videoProfile: null,
//     channel: null,
//   transcode: null,
//   attendeeMode: null,
//   baseMode: null,
//   appId: APP_ID,
//   token: null,
//   enabled: false
// }
const INIT_STATE = {
  videoCalls: [],
  appID: null,
  currentVideoCall: {},
  openVideoCall: false,
  baseConfiguration: {
    baseMode: "avc",
    transcode: "interop",
    attendeeMode: "video",
    videoProfile: "1080p_1",
    time: 0
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_VIDEO_CALL: {
      let videoCallConfiguration = {
        ...state.baseConfiguration,
        ...action.payload,
        channel: get(action.payload, "channelName"),
        id: last(split(get(action.payload, "channelName"), ":")),
        token: get(action.payload, "token"),
        ready: get(action.payload, "ready"),
        account: get(action.payload, "account"),
        appId: get(action.payload, "appId", state.appID),
        uid: get(action.payload, "account"),
      };

      return {
        ...state,
        videoCalls: [...state.videoCalls, videoCallConfiguration],
      };
    }

    case SET_VIDEO_CALL: {
      let videoCallConfiguration = {
        ...state.baseConfiguration,
        ...action.payload,
        channel: get(action.payload, "channelName"),
        id: last(split(get(action.payload, "channelName"), ":")),
        token: get(action.payload, "token"),
        ready: get(action.payload, "ready"),
        account: get(action.payload, "account"),
        appId: get(action.payload, "appId", state.appID),
        uid: get(action.payload, "account")
      };
      const videoCallChannel = get(videoCallConfiguration, "channel");
      let findVideoCall = state.videoCalls.find(
        (videoCall) => get(videoCall, "channel") === videoCallChannel
      );
      findVideoCall = { ...findVideoCall, ...videoCallConfiguration };
      const filterVideoCalls = state.videoCalls.filter(
        (videoCall) => get(videoCall, "channel") !== videoCallChannel
      );
      return { ...state, videoCalls: [...filterVideoCalls, ...action.payload] };
    }

    case CURRENT_VIDEO_CALL: {
      const findVideoCall = state.videoCalls.find(
        (videoCall) =>
          get(videoCall, "channel") === action.payload
      );
      return { ...state, currentVideoCall: findVideoCall };
    }

    case REMOVE_VIDEO_CALL: {
      const videoCallChannel = get(action.payload, "channel");
      const filterVideoCalls = state.videoCalls.filter(
        (videoCall) => get(videoCall, "channel") !== videoCallChannel
      );
      return { ...state, videoCalls: filterVideoCalls };
    }

    case FLAG_VIDEO_CALL: {
      return {
        ...state, openVideoCall: action.payload
      }
    }

    case REFRESH_TOKEN_CALL: {
      const channel = get(action.payload, "channel");
      let videoInfo = state.videoCalls.find(video => get(video, "channel") === channel);
      const arrayOfVideos = state.videoCalls.filter(video => get(video, "channel") !== channel);
      videoInfo = { ...videoInfo, token: get(action.payload, "token") };
      let currentVideo = state.currentVideoCall;
      if (channel === get(state.currentVideoCall, "channel")) {
        currentVideo = { ...currentVideo, token: get(action.payload, "token") }
      }
      return {
        ...state, videoCalls: [...arrayOfVideos, videoInfo], currentVideoCall: { ...state.currentVideoCall, ...currentVideo }
      }
    }

    case COUNT_TIMER_CALL: {
      const arrayOfVideos = state.videoCalls.filter(video => get(video, "channel") !== get(state.currentVideoCall, "channel"));
      return { ...state, videoCalls: [...arrayOfVideos, { ...state.currentVideoCall, time: action.payload }], currentVideoCall: { ...state.currentVideoCall, time: action.payload } }
    }

    default:
      return state;
  }
};
