import _ from 'lodash';
import { GET_CATEGORY, ADD_CATEGORY, SELECT_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY} from "../../constants/Categories";
const INIT_STATE = {
  categories: [],
  count: 0,
  categorySelected: {}
}

export default (state = INIT_STATE, action) => {
switch(action.type) {
  case GET_CATEGORY: {
    return { ...state, categories: action.payload, count: action.count }
  }
  case ADD_CATEGORY: {
    return { ...state, categories: [...state.categories, action.payload], count: state.count + 1 }
  }
  case SELECT_CATEGORY: {
    return { ...state, categorySelected: action.payload ? action.payload : {}}
  }
  case UPDATE_CATEGORY: {
    return { ...state, categories: [...state.categories.filter(item=> item._id !== action.payload._id), action.payload] }
  }
  case DELETE_CATEGORY: {
    let categories;
    let count = 1;
    if (_.isArray(action.payload)) {
      const compare = action.payload.map(item => ({_id: item}));
      count = action.payload.length;
      categories = _.differenceBy(state.categories, compare, "_id")
    } else {
      categories = state.categories.filter(category => category._id !== action.payload);
    }
    return { ...state, categories: categories , count: state.count - count }
  }
  default:
    return state
}
}

