import React from "react";
// import loader from "../../assets/images/loaderDokto.svg"

const CircularProgress = ({className}) => <div className={`loader ${className}`}>
  <div id="loaderWrapper">
      <svg id="icon-location" version="1.1"
        x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60" enableBackground="new 0 0 60 60" space="preserve">
        <path fill="#261A6B" d="M9.3,19.7C8.5,18.9,2,12.2,2,8c0-4.4,3.6-8,8-8s8,3.6,8,8c0,4.2-6.5,10.9-7.3,11.7C10.3,20.1,9.7,20.1,9.3,19.7z M10,2
                    C6.7,2,4,4.7,4,8c0,2.5,3.8,7.2,6,9.5c2.2-2.3,6-7,6-9.5C16,4.7,13.3,2,10,2z" />
        <circle cx="10" cy="8" r="2" fill="#21CE99" />
      </svg>
  </div>
</div>;
export default CircularProgress;
