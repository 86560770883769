import axios from  "../../util/Api";

export const REMOVE_INVITE_TOKEN = 'InviteCollab/REMOVE_INVITE_TOKEN';
export const SET_INVITE_TOKEN = 'InviteCollab/SET_INVITE_TOKEN';

export const ADD_COLLABORATOR = 'InviteCollab/ADD_COLLABORATOR';
export const ADD_COLLABORATOR_SUCCESS = 'InviteCollab/ADD_COLLABORATOR_SUCCESS';
export const ADD_COLLABORATOR_ERROR = 'InviteCollab/ADD_COLLABORATOR_ERROR';

export const GET_INVITE_LINK = 'InviteCollab/GET_INVITE_LINK';
export const GET_INVITE_LINK_SUCCESS = 'InviteCollab/GET_INVITE_LINK_SUCCESS';
export const GET_INVITE_LINK_ERROR = 'InviteCollab/GET_INVITE_LINK_ERROR';

export const removeInviteToken = () => ({
  type: REMOVE_INVITE_TOKEN
});

export const setInviteToken = token => ({
  type: SET_INVITE_TOKEN,
  payload: {
    token
  }
});

export const addCollaboratorSuccess = () => ({
  type: ADD_COLLABORATOR_SUCCESS
});

export const addCollaboratorError = error => ({
  type: ADD_COLLABORATOR_ERROR,
  payload: {
    error
  }
});

export const addCollaborator = token => async dispatch => {
  dispatch({
    type: ADD_COLLABORATOR,
    payload: {
      token
    }
  });

  try {
    await axios.put(`/hooks/link/${token}`);

    dispatch(addCollaboratorSuccess());
    return Promise.resolve();
  } catch (error) {
    dispatch(addCollaboratorError(error));
    return Promise.reject();
  }
};

export const getInviteLinkSuccess = link => ({
  type: GET_INVITE_LINK_SUCCESS,
  payload: {
    link
  }
});

export const getInviteLinkError = error => ({
  type: GET_INVITE_LINK_ERROR,
  payload: {
    error
  }
});

export const getInviteLink = () => async dispatch => {
  dispatch({
    type: GET_INVITE_LINK
  });

  try {
    const { data } = await axios.post('/hooks/link');

    dispatch(getInviteLinkSuccess(data.link));
  } catch (error) {
    dispatch(getInviteLinkError(error));
  }
};
