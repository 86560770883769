import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import AffiliateTypes from './AffiliateType';
import Affiliate from './Affiliates';
import Service from './Service';
import Category from './Category';
import SubCategory from './subCategory';
import Insurances from './insurances';
import Patients from './patients';
import Appointments from './Appointments';
import InviteCollab from './InviteCollab';
import Collaborators from './Collaborators';
import Employees from "./Employees";
import Messages from "./Messages";
import VideoCall from "./VideoCall";

// import { reducer as formReducer } from 'redux-form';
import FormReducer from './form';
import Alerts from "./Alerts";
const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  commonData: Common,
  AffiliateTypes,
  Service,
  Category,
  SubCategory,
  Affiliate,
  // form: formReducer,
  FormReducer,
  Insurances,
  Patients,
  Appointments,
  InviteCollab,
  Collaborators,
  Employees,
  Messages,
  VideoCall,
  Alerts
});

export default reducers;
