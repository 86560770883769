import { ADD_AFFILIATE, GET_AFFILIATE, SELECT_AFFILIATE, DELETE_AFFILIATE, UPDATE_AFFILIATE } from '../../constants/Affiliates';
import _ from 'lodash';

const INIT_STATE = {
  affiliates: [],
  count: 0,
  affiliateSelected: {}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_AFFILIATE: {
      return { ...state, affiliates: [...state.affiliates, action.payload], count: state.count + 1 }
    }
    case GET_AFFILIATE: {

      return {
        ...state,
        affiliates: action.payload,
        count: action.count
      }
    }
    case SELECT_AFFILIATE: {
      return { ...state, affiliateSelected: action.payload ? action.payload : {} }
    }
    case UPDATE_AFFILIATE: {
      const index = state.affiliates.findIndex(item => item._id === action.payload._id);
      const newStateAffiliates = [...state.affiliates];
      newStateAffiliates[index] = action.payload;
      return { ...state, affiliates: newStateAffiliates }
    }
    case DELETE_AFFILIATE: {
      let affiliates;
      let count = 1;
      if (_.isArray(action.payload)) {
        const compare = action.payload.map(item => ({ _id: item }));
        count = action.payload.length;
        affiliates = _.differenceBy(state.affiliates, compare, "_id")
      } else {
        affiliates = state.affiliates.filter(affiliate => affiliate._id !== action.payload);
      }
      return { ...state, affiliates: affiliates, count: state.count - count }
    }
    default:
      return state;
  }
}
