import {
  USERS_ADD,
  USERS_SELECTED,
  USERS_GET,
  USERS_REMOVE,
  USERS_SELECTED_CLEAN,
  ALL_NOTIFICATIONS, FECTH_BY_CHANNEL
} from "../../constants/Messages";
import { differenceBy, uniqBy, get, orderBy } from "lodash";
const INIT_STATE = {
  users: [],
  usersSelected: [],
  notifications: [],
  usersByChannel: [],
  usersByChannelTotal: 0
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USERS_GET: {
      const users = orderBy(differenceBy(action.payload, state.usersSelected, '_id'), 'fullName');
      return { ...state, users: [...users] }
    }
    case USERS_SELECTED: {
      const users = uniqBy(state.usersSelected, '_id');
      return { ...state, usersSelected: users }
    }
    case USERS_ADD: {
      const usersSelected = [...state.usersSelected, action.payload];
      const users = differenceBy(state.users, usersSelected, '_id');
      return {...state, usersSelected: usersSelected, users: users}
    }
    case USERS_REMOVE: {
      const usersRemoves = uniqBy(state.usersSelected.filter(user => get(user, '_id') !== get(action, 'payload._id')), '_id');
      const users = orderBy(differenceBy(uniqBy([...state.users, action.payload], '_id'), '_id'),'fullName');
      return {
        ...state, users: users, usersSelected: usersRemoves
      }
    }
    case USERS_SELECTED_CLEAN: {
      const users = uniqBy([...state.users, ...state.usersSelected], "_id");
      return {
        ...state, usersSelected: [], users: users
      }
    }
    case ALL_NOTIFICATIONS: {
      return {
        ...state, notifications: action.payload
      }
    }
    case FECTH_BY_CHANNEL: {
      return {
        ...state, usersByChannel: action.payload, usersByChannelTotal: action.total
      }
    }
    default:
      return state;
  }
}
