import axios from 'axios';
// import configureStore, {history} from "../appRedux/store";
// import { userSignOut } from "../appRedux/actions/Auth";
// const enviroment = process.env.REACT_APP_ENV?process.env.REACT_APP_ENV.trim():'';
// const API_URL = process.env[`REACT_APP_URL_${enviroment}`];

// if(process.env.REACT_APP_ENV === 'DEV') {
//   console.log(`api listen on ${API_URL}`);
// }
const API_URL = process.env.REACT_APP_URL_PROD; 

if (process.env.REACT_APP_URL_PROD) {
    console.log(`api listen on ${API_URL}`);
}

// export default axios.create({
//   baseURL: `${API_URL}`,
//   crossDomain: true
// });
const client = axios.create({
  baseURL: `${API_URL}`,
  // crossDomain: true,
  headers: {
   "Access-Control-Allow-Origin": "*"
  }
});

// const { dispatch } = configureStore();
// client.interceptors.response.use(
//   response => response,
//   error => {
//     const {status} = error.response;
//     if (status === 401) {
//       dispatch(userSignOut());
//     }
//    return Promise.reject(error);
//  }
// );

export default client;