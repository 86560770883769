import { GET_INSURANCES, ADD_INSURANCE } from "../../constants/insurances";
const INIT_STATE = {
  insurances: [],
  count: 0,
  insuranceSelected: {}
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
    case GET_INSURANCES: {
      return { ...state, insurances: action.payload, count: action.count }
    }
    default:
      return state;
  }
}
