import { GET_SERVICE, ADD_SERVICE, SELECT_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from '../../constants/Service';
import _ from 'lodash';
const INIT_STATE = {
  services: [],
  count: 0,
  serviceSelected: {}
}


export default (state = INIT_STATE, action) => {
  switch(action.type) {
    case GET_SERVICE: {
      return { ...state, services: action.payload, count: action.count }
    }
    case ADD_SERVICE: {
      return { ...state, services: [...state.services, action.payload], count: state.count + 1 }
    }
    case SELECT_SERVICE: {
      return { ...state, serviceSelected: action.payload ? action.payload : {}}
    }
    case UPDATE_SERVICE: {
      return { ...state, services: [...state.services.filter(item=> item._id !== action.payload._id), action.payload] }
    }
    case DELETE_SERVICE: {
      let services;
      let count = 1;
      if (_.isArray(action.payload)) {
        const compare = action.payload.map(item => ({_id: item}));
        count = action.payload.length;
        services = _.differenceBy(state.services, compare, "_id")
      } else {
        services = state.services.filter(service => service._id !== action.payload);
      }
      return { ...state, services: services , count: state.count - count }
    }
    default:
      return state
  }
}
