import React, { Component } from "react";
import Nprogress from "nprogress";
import "nprogress/nprogress.css";
import ReactPlaceholder from "react-placeholder";
import CircularProgress from "components/CircularProgress";
export default function asyncComponent(getComponent) {
  class AsyncComponent extends Component {
    static Component = null;
    state = { Component: AsyncComponent.Component };

    componentWillMount() {
      Nprogress.start();
    }

    componentWillMount() {
      if (!this.state.Component) {
        getComponent().then(Component => {
          AsyncComponent.Component = Component;
          this.setState({ Component });
        });
      }
    }

    async componentDidMount() {
      Nprogress.done();
    }
    render() {
      const { Component } = this.state;
      if (Component) {
        return(
        <ReactPlaceholder type="text" rows={7} ready={true}>
          <Component {...this.props} />
        </ReactPlaceholder>
        )
      } else {
        return <CircularProgress />;
      }
    }
  }
  return AsyncComponent;
}
