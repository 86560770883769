import { ADD_AFFILIATE_TYPE, GET_AFFILIATE_TYPE, SELECT_AFFILIATE_TYPE, DELETE_AFFILIATE_TYPE, UPDATE_AFFILIATE_TYPE } from '../../constants/AffiliateTypes';
import _ from 'lodash';

const INIT_STATE = {
  affiliateTypes: [],
  count: 0,
  affiliateTypeSelected: {}
}

export default (state = INIT_STATE, action) => {
  switch(action.type) {
    case ADD_AFFILIATE_TYPE: {
      return { ...state, affiliateTypes: [...state.affiliateTypes, action.payload], count: state.count + 1 }
    }
    case GET_AFFILIATE_TYPE: {
      return { ...state, affiliateTypes: action.payload, count: action.count }
    }
    case SELECT_AFFILIATE_TYPE: {
      return { ...state, affiliateTypeSelected: action.payload ? action.payload : {}}
    }
    case UPDATE_AFFILIATE_TYPE: {
      return { ...state, affiliateTypes: [...state.affiliateTypes.filter(item=> item._id !== action.payload._id), action.payload] }
    }
    case DELETE_AFFILIATE_TYPE: {
      let affiliateTypes;
      let count = 1;
      if (_.isArray(action.payload)) {
        const compare = action.payload.map(item => ({_id: item}));
        count = action.payload.length;
        affiliateTypes = _.differenceBy(state.affiliateTypes, compare, "_id")
      } else {
        affiliateTypes = state.affiliateTypes.filter(affiliates => affiliates._id !== action.payload);
      }
      return { ...state, affiliateTypes: affiliateTypes , count: state.count - count }
    }
    default:
      return state;
  }
}
