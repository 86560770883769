import * as actions from '../actions/Collaborators';

const initialState = {
  collaborators: null,
  fetchingCollaborators: false
};

function collaboratorsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_COLLABORATORS:
      return {
        ...state,
        fetchingCollaborators: true
      };
    case actions.GET_COLLABORATORS_SUCCESS:
      return {
        ...state,
        fetchingCollaborators: false,
        collaborators: action.payload.collaborators
      };
    case actions.GET_COLLABORATORS_ERROR:
      return {
        ...state,
        fetchingCollaborators: false
      };
    default:
      return state;
  }
}

export default collaboratorsReducer;
