import {GET_ALERTS, NEW_ALERT, OPEN_CLOSE_ALERTS} from "../../constants/Alerts";


const INIT_STATE = {
  open_close_alerts: false,
  alerts: [],
  newAlert: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case OPEN_CLOSE_ALERTS: {
      return {
        ...state, open_close_alerts: !state.open_close_alerts
      }
    }

    case GET_ALERTS: {
      return {
        ...state, alerts: action.payload
      }
    }

    case NEW_ALERT: {
      const alerts = state.alerts.filter(alert => alert._id !== action.payload._id);

      return {
        ...state, newAlert: action.payload,
        alerts: [action.payload, ...alerts ]
      }
    }
    default:
      return state;
  }
}
