import _ from 'lodash';
import { GET_SUBCATEGORY, ADD_SUBCATEGORY, SELECT_SUBCATEGORY, UPDATE_SUBCATEGORY, DELETE_SUBCATEGORY} from "../../constants/subCategories";
const INIT_STATE = {
  subCategories: [],
  count: 0,
  subCategorySelected: {}
}

export default (state = INIT_STATE, action) => {
switch(action.type) {
  case GET_SUBCATEGORY: {
    return { ...state, subCategories: action.payload, count: action.count }
  }
  case ADD_SUBCATEGORY: {
    return { ...state, subCategories: [...state.subCategories, action.payload], count: state.count + 1 }
  }
  case SELECT_SUBCATEGORY: {
    return { ...state, subCategorySelected: action.payload ? action.payload : {}}
  }
  case UPDATE_SUBCATEGORY: {
    return { ...state, subCategories: [...state.subCategories.filter(item=> item._id !== action.payload._id), action.payload] }
  }
  case DELETE_SUBCATEGORY: {
    let subCategories;
    let count = 1;
    if (_.isArray(action.payload)) {
      const compare = action.payload.map(item => ({_id: item}));
      count = action.payload.length;
      subCategories = _.differenceBy(state.subCategories, compare, "_id")
    } else {
      subCategories = state.subCategories.filter(subcategory => subcategory._id !== action.payload);
    }
    return { ...state, subCategories: subCategories , count: state.count - count }
  }
  default:
    return state
}
}

