import io from 'socket.io-client';
import { Observable } from 'rxjs';
import { get } from "lodash";
const API_URL = process.env[`REACT_APP_SOCKET_${process.env.REACT_APP_ENV}`];
class SocketIO {
  socket: any;
  connect() {
    this.socket = io(API_URL, {
      query: 'auth_token=' + JSON.parse(localStorage.getItem('tokendto')),
      transports: ['websocket'],
      // upgrade: false,
      allowUpgrades: false,
      pingTimeout: 10000,
      upgradeTimeout: 30000
    });
    this.socket.connect();
    this.socket.on("success", (msg) => {
      console.log(msg.message + " " + API_URL)
    })
  }

  onMessage(channel) {
    return new Observable(observer => {
      this.socket.on(channel, (data) => observer.next(data));
    });
  }
  sendMessage(channel, msg) {
    this.socket.emit(channel, msg);
  }

  disconnect() {
    if(get(this.socket, "disconnect", undefined)) {
      this.socket.disconnect(true);
    }
  }
}

export default new SocketIO();
