import React from "react";
import {ConnectedRouter} from "react-router-redux";
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";

import asyncComponent from "./util/AsyncComponents"
import configureStore, {history} from "./appRedux/store";
// import App from "./containers/App/index";
import axios from "./util/Api";
import { userSignOut } from "./appRedux/actions/Auth"
const App = asyncComponent(() => import('./containers/App/index').then(module => module.default))
export const store = configureStore();

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;



const { dispatch } = store;
axios.interceptors.response.use(
  response => response,
  error => {
    if(!error.response) {
      return Promise.reject(error);
    }
    const {status} = error.response;
    if (status === 401) {
      dispatch(userSignOut());
    }
   return Promise.reject(error);
 }
);

export default NextApp;
