import {
  ADD_APPOINTMENT,
  GET_APPOINTMENT,
  UPDATE_APPOINTMENT,
  GET_REPORT_APPOINTMENTS_GROUP_STATUS,
  CALC_DATA_APPOINTMENTS_GROUP,
  ADD_REPORT_APPOINTMENTS_GROUP_STATUS,
  DEBTS_FUNDS_APPOINTMENTS,
  SET_QUERY,
  DELETE_APPOINTMENTS,
  DELETE_APPOINTMENT,
  ADD_OR_UPDATE_APPOINTMENTS
} from "../../constants/Appointments";
import * as _ from "lodash";
import moment from "moment-timezone";
const INIT_STATE = {
  appointments: [],
  count: 0,
  selectedAppointment: {},
  reportAppointmentsGroupByStatus: {},
  calcDataAppointmentsGroup: {},
  balanceFounds: {},
  query: {}
};

export default function(state = INIT_STATE, action) {
  switch (action.type) {
    //
    case ADD_APPOINTMENT: {
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
        count: state.appointments.length + 1
      };
    }
    case ADD_OR_UPDATE_APPOINTMENTS: {
      const find = state.appointments.find(appointment => appointment._id === _.get(action, "payload._id"));
      if (!_.isEmpty(find)) {
        return {
          ...state,
          appointments: [
            ...state.appointments.filter(app => app._id !== action.payload._id),
            action.payload
          ]
        }
      }
        return {
          ...state,
          appointments: [...state.appointments, action.payload],
          count: state.appointments.length + 1
        };
    }

    //
    case GET_APPOINTMENT: {
      return {
        ...state,
        appointments: [...action.payload],
        count: action.payload.length
      };
    }
    //
    case UPDATE_APPOINTMENT: {
      return {
        ...state,
        appointments: [
          ...state.appointments.filter(app => app._id !== action.payload._id),
          action.payload
        ]
      };
    }
    //
    case DELETE_APPOINTMENT: {
      return {
        ...state,
        appointments: [
          ...state.appointments.filter(app => app._id !== action.payload)
        ]
      };
    }
    //
    case GET_REPORT_APPOINTMENTS_GROUP_STATUS: {
      return {
        ...state,
        reportAppointmentsGroupByStatus: { ...action.payload },
        query: {...action.query }
      };
    }
    //
    case CALC_DATA_APPOINTMENTS_GROUP: {
      if(_.isEmpty(state.reportAppointmentsGroupByStatus)) {
        return {
          ...state,
          calcDataAppointmentsGroup: {}
        }
      }
      let datas = _.values(_.get({...state.reportAppointmentsGroupByStatus}, "data"));
      datas = datas.map(item => ({ ...item, dates: item.dates.map(date => moment(date).toDate()) }))
      const nextAppointments = _.flattenDeep(
        datas
          .filter(
            item =>
              item._id === "scheduled" ||
              item._id === "confirmed" ||
              item._id === "re-scheduled"
          )
          .map(item => item.dates)
      );
      const completedAppointments =  _.flattenDeep(datas
        .filter(item => item._id === "completed")
        .map(item => item.dates));
      const canceledAppointments =  _.flattenDeep(datas
        .filter(item => item._id === "canceled")
        .map(item => item.dates));

      const calcDataAppointmentsGroup = {
        nextAppointments,
        completedAppointments,
        canceledAppointments
      };
      return {
        ...state,
        calcDataAppointmentsGroup: {
          ...state.calcDataAppointmentsGroup,
          ...calcDataAppointmentsGroup
        }
      };
    }
    //
    case ADD_REPORT_APPOINTMENTS_GROUP_STATUS: {
      const appointment = { ...action.payload };
      const nextAppointments = [...state.calcDataAppointmentsGroup];
      const completedAppointments = [...state.completedAppointments];
      const canceledAppointments = [...state.canceledAppointments];
      switch (appointment.status) {
        case "scheduled" || "confirmed" || "re-schedule":
          nextAppointments.push(appointment.date);
          break;
        case "completed":
          completedAppointments.push(appointment.date);
        case "canceled":
          canceledAppointments.push(appointment.date);
      }
      return {
        ...state,
        calcDataAppointmentsGroup: {
          ...state.calcDataAppointmentsGroup, nextAppointments, completedAppointments, canceledAppointments,
        }
      };
    }
    case DEBTS_FUNDS_APPOINTMENTS: {
      return {
        ...state,
        balanceFounds: action.payload
      }
    }
    case SET_QUERY: {
      return {
        ...state,
        query: { ...action.payload  }
      }
    }
    case DELETE_APPOINTMENTS: {
      return {
        ...state,
        appointments: []
      }
    }
    default:
      return state;
  }
}
