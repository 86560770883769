import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL, SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from '../../constants/ActionTypes';
import axios from 'util/Api';
import { addCollaborator } from './InviteCollab';
import SocketIO from "util/socket.io";
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};


export const userSignUp = props => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      try {
        dispatch({ type: FETCH_START });
        axios
          .post('auth/preaffiliate', props)
          .then(({ data }) => {
            dispatch({ type: FETCH_SUCCESS });
            resolve(data);
          })
          .catch(({ response }) => {
            dispatch({
              type: FETCH_ERROR,
              payload: response ? response.data.message : 'network.error'
            });
            reject(response);
          });
      } catch (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        reject(error);
      }
    });
  };
};

// export const userSignUp = (props) => {
//   return async (dispatch) => {
//     try {
//     dispatch({type: FETCH_START});
//       const data = await axios.post('auth/preaffiliate', props);
//       if (data.data) {
//         // localStorage.setItem("token", JSON.stringify(data.token.access_token));
//         // axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
//         dispatch({type: FETCH_SUCCESS});
//         // dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
//         // dispatch({type: USER_DATA, payload: data.user});
//         return data;
//       } else {
//         throw data;
//       }
//     } catch (error) {
//       debugger;
//       dispatch({type: FETCH_ERROR, payload: error.message});
//       throw error;
//     }
//     // .then(({data}) => {
//     // }).catch(function (error) {
//     //   dispatch({type: FETCH_ERROR, payload: error.message});
//     //   console.log("Error****:", error.message);
//     //   throw error;
//     // });
//   }
// };

export const userSignIn = ({ email, password }) => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });

    axios
      .post('auth/login', {
        username: email,
        password: password
      })
      .then(({ data }) => {
        if (data) {
          localStorage.setItem('tokendto', JSON.stringify(data.token));
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + data.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
          dispatch({ type: USER_TOKEN_SET, payload: data.token });

          const state = getState();
          const inviteToken = state.InviteCollab.token;

          if (inviteToken) dispatch(addCollaborator(inviteToken));
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        const { response } = error;
        dispatch({
          type: FETCH_ERROR,
          payload: response ? response.data.message : error.message
        });
        console.log('Error****:', error.message);
      });
  };
};

export const userSignInToken = token => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      try {
        // dispatch({ type: FETCH_START });

        axios
          .post('auth/logintoken', {
            username: token,
            password: token
          })
          .then(({ data }) => {
            if (data) {
              localStorage.setItem('tokendto', JSON.stringify(data.token));
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + data.token;
              // dispatch({type: FETCH_SUCCESS});
              dispatch({ type: USER_DATA, payload: data.user });
              dispatch({ type: USER_TOKEN_SET, payload: data.token });
              resolve(data);
            } else {
              dispatch({ type: FETCH_ERROR, payload: data.error });
              reject({ error: { message: 'noData' } });
            }
          })
          .catch(function(error) {
            const { response } = error;
            dispatch({
              type: FETCH_ERROR,
              payload: response ? response.data.message : error.message
            });
            reject(response);
            // console.log("Error****:", error.message);
          });
      } catch (err) {
        reject(err);
      }
    });
  };
};

export const getUser = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({ type: FETCH_START });
      axios
        .post('/auth/me')
        .then(({ data }) => {
          if (data.user) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: USER_DATA, payload: data.user });
            resolve(data.user);
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.error });
            reject(data.error);
          }
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          // console.log("Error****:", error.message);
          reject(error.message);
        });
    });
  };
};

export const userSignOut = () => {
  return dispatch => {
    localStorage.removeItem('tokendto');
    setTimeout(() => {
      dispatch({ type: 'RESET' });
      SocketIO.disconnect();
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      setTimeout(() => {
        dispatch({ type: SIGNOUT_USER });
      }, 2000)

    }, 200);
  };
};
