import { ADD_EMPLOYEES, GET_EMPLOYEES, LOADING_EMPLOYEES, UPDATE_EMPLOYEES, DELETE_EMPLOYEES } from "constants/Employees";
import _ from 'lodash';
const INIT_STATE = {
  employees: [],
  count: 0,
  employeeSelected: {},
  loading: false
}


export default (state = INIT_STATE, action) => {
  switch(action.type) {
    case ADD_EMPLOYEES: {
      return { ...state, employees: [...state.employees, action.payload], count: state.count + 1 }
    }
    case GET_EMPLOYEES: {
      return { ...state, employees: action.payload, count: action.count }
    }
    case LOADING_EMPLOYEES: {
      return { ...state, loading: action.payload }
    }
    case UPDATE_EMPLOYEES: {
      const employees = state.employees.filter(employee => employee._id !== action.payload._id );
      return { ...state, employees: [...employees, action.payload ] }
    }
    case DELETE_EMPLOYEES: {
      const employees = state.employees.filter(employee => employee._id !== action.payload );
      return { ...state, employees: [...employees ], count: state.count - 1 }
    }
    default:
      return state;
  }
}
