
import { UPDATE_FORM, RESET_FORM } from '../../constants/form';
const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
    case UPDATE_FORM: {
      return { ...state, ...action.payload };
    }
    case RESET_FORM: {
      return { };
    }
  default:
  return state;
  }
}


